import React, { useState } from 'react';
import './ReviewForm.css';
import axios from 'axios';
import { BASEIMAGEURL } from '../api/BaseApi';

const ReviewForm = () => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [review, setReview] = useState('');
    const [image, setImage] = useState();
    const [loader, setLoader] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const form = new FormData();
            form.append("image", image);
            const response = await axios.post(BASEIMAGEURL + "/create-review", form);
            if (response.status == 200) {
                window.location.reload();
            }
        } catch (error) {
            setLoader(false);

            if (error.response) {
                // Server responded with a status other than 200
                alert(`Error: ${error.response.data.message || 'An error occurred while submitting the review.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                alert("Error: No response from the server. Please try again later.");
            } else {
                // Other errors (like setting up the request)
                alert(`Error: ${error.message}`);
            }

        }
    };

    return (
        <div className="review-form-container">

            <div className="fb-form">
                <form action="#" className="form-group">
                    <h2>Tell us what you think</h2>
                    <input className="form-control" placeholder="Name" type="text" />
                    <input className="form-control" placeholder="Email" type="text" />
                    <textarea
                        className="form-control"
                        id="fb-comment"
                        name=""
                        placeholder="Tell us what you think"
                        defaultValue={""}
                    />
                    <div className="rating">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                    </div>
                    <input className="form-control btn btn-primary" type="submit" />
                </form>
            </div>

        </div>
    );
};

export default ReviewForm;
