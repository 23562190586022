import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Packages from "./Pages/Packages";
import Policy from "./Pages/Policy";
import Portfolio from "./Pages/Portfolio";
import Testimonial from "./Pages/Testimonial";
import ScrollToTop from './ScrollToTop';
import FAB from "./Components/Fab";
import ThankYou from "./Pages/ThankYou";

function App() {



  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/thankyou" element={<ThankYou />} />

      </Routes>
      <Footer />

      <div style={{ position: "absolute", bottom: 0, right: 30 }}>
        <FAB />
      </div>
    </BrowserRouter>
  );
}

export default App;
