import React from 'react';
import HomeForm from '../Components/HomeForm';
import { useMainContext } from '../Context/MainContext';
import ReviewForm from './ReviewForm';

const data = {
  "testimonials": [
    {
      "type": "google_rev",
      "initial": "SB",
      "content": {
        "quote": "“Totally the agency you would want to be on your side! I love the way they take interest in your work and treat is like their very own, phenomenal creations and delivery.”",
        "name": "Sarah Brendon",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "CM",
      "content": {
        "quote": "“Top-notch service, incredible designs. There is nothing more you could ask for! Thanks to the Mad Alpha Designers Team for their remarkable effort.”",
        "name": "Chad Miller",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "LT",
      "content": {
        "quote": "“You just cannot ask more of them. They give it all. Amazing concepts. Creative suggestions. Good customer service and fast turnaround times.”",
        "name": "Linda Trent",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "PH",
      "content": {
        "quote": "“We were very impressed with the all aspects of our new website. We would highly recommend Mad Alpha Designers to anyone looking to build a new website.”",
        "name": "Patricia Hersey",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "TS",
      "content": {
        "quote": "“Gave importance to each and every minor detail, I conveyed my message to the designers and they understood it well. Delivered as expected, highly recommended.”",
        "name": "Tyler Sawyer",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "NT",
      "content": {
        "quote": "“It was an exceptional experience working with Mad Alpha Designers. Creative minds who truly think out of the box and are so good at working with abstract ideas that you present to them. Loved the designs.”",
        "name": "Nelly Taylor",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "BR",
      "content": {
        "quote": "“I went to Mad Alpha Designers with a basic 'feel' I wanted in a website. They helped flush out the visual layout and content. The web design services are bargain when you weigh the cost versus the product you receive. I will be using them again in future endeavors. Thanks again!”",
        "name": "Bradley Ross",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "JN",
      "content": {
        "quote": "“Mad Alpha Designers designed and developed my company's website. Their attitude to work was exceptional. I received great customer care throughout the process. I am pleased with the quality of work and will recommend their services to everyone.”",
        "name": "Justin Northcutt",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "TW",
      "content": {
        "quote": "“The video looks absolutely brilliant. Thank you. And the service and dedication to getting this done on a tight deadline has been impeccable”",
        "name": "Tracy Winkles",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "DM",
      "content": {
        "quote": "“Great logos received. Outstanding Experience working with the team. Prompt responses from customer support.”",
        "name": "Dana Morse",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "JA",
      "content": {
        "quote": "“It’s been a real pleasure working with you guys and will be sure that Mad Alpha Designers is our first point of call in the future regarding animations. Professional, friendly and more than helpful!!!”",
        "name": "Joseph Abney",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "JN",
      "content": {
        "quote": "“The guys at Mad Alpha Designers were exceptional in the production of our animated video. Everything from initial concepts to voiceover options were delivered to the highest standards and in a timely manner.”",
        "name": "James Nealon",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "JW",
      "content": {
        "quote": "“Working with the Mad Alpha Designers team has been a most pleasant experience! Their dedication and passion for what they do is exemplified by their unrivaled customer support and attentiveness to the specific needs of our business. We look forward to a long lasting and prosperous relationship with the crew at Mad Alpha Designers.”",
        "name": "Jacqueline Williams",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "RR",
      "content": {
        "quote": "“Mad Alpha Designers’s website design team far exceeded my expectations on every front, and I had very high expectations. The end product is a beautiful website that embodies who we are as company. Every person I worked with at Mad Alpha Designers was knowledgeable, creative, prompt, and personable.”",
        "name": "Ronald Richmond",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "JC",
      "content": {
        "quote": "“Another GREAT experience working with Mad Alpha Designers! Having 2 projects in a row go so smoothly I’ll definitely be telling people about my experience! Very easy to work with and very responsive. Thank you Mad Alpha Designers!”",
        "name": "John Crouch",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "AP",
      "content": {
        "quote": "“I want to thank the Mad Alpha Designers Team for the Excellent Work that they have delivered. From the beginning to the end, the team members were professional, courteous, responsive and on time. Highly recommended!”",
        "name": "Anna Petersen",
        "rating": 5
      }
    },
    {
      "type": "trust_rev",
      "initial": "BC",
      "content": {
        "quote": "“With Mad Alpha Designers’s help, we were able to increase the functionality of our website dramatically while cutting our costs. Our website is much more easy to use, has tons of more features than before and is incredibly easy to maintain. We could not be happier with our new website! Thanks Mad Alpha Designers!”",
        "name": "Bran Coleman",
        "rating": 5
      }
    },
    {
      "type": "google_rev",
      "initial": "DH",
      "content": {
        "quote": "“I have worked with Mad Alpha Designers for a while now. They helped me understand the difference between good content and great content. On a side note they taught me what contextual marketing is and I use that term as in conversation as much as I can. Communication is great as well, which has seemed to be lost in today's time. Look forward to growing my business with them.”",
        "name": "Dorothy Hunter",
        "rating": 5
      }
    }
  ]
}


const Testimonial = () => {

  // const el = React.useRef(null);
  const { allTestimonial } = useMainContext();

  // React.useEffect(() => {
  //   const typed = new Typed(el.current, {
  //     strings: ['Client Reviews'],
  //     typeSpeed: 50,
  //     loop: true,
  //     startDelay: 500
  //   });

  //   return () => {
  //     // Destroy Typed instance during cleanup to stop animation
  //     typed.destroy();
  //   };
  // }, []);
  return (
    <>
      <section
        className="inner-banner t-banner"
        style={{
          // background: 'linear-gradient(197deg, #312969, #0000008a)'
          backgroundColor: "#bf8344",
          backgroundImage: window.innerWidth < 750 ? 'url(images/MOBILE-VIEW_Testimonials.png)' : "url(images/Testimonial-Banner.png)",
          height: "100vh"
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 banner-left ">
              {/* <h1>
                <span ref={el} />

              </h1> */}
              {/* <p>
                At Mad Alpha Designer, we hold the conviction that hidden within
                each impossibility is a possibility eager to emerge. Our team,
                composed of imaginative visionaries and adept problem-solvers, is
                devoted to transcending conventional limitations. We aim to assist
                brands in realizing their aspirations by exploring the uncharted
                territories of creativity and innovation.
              </p> */}
              {/* <a
                data-fancybox=""
                href="javascript:void(0)"
                data-src="#popupformWebsite"
                title="Lets Get Started"
                className="banner-btn yb-btn various"
                style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
              >
                Get Touch In
              </a> */}
            </div>
            {/* <div className="col-md-6  banner-right004">
              <img src={require("../assets/images/testimonial.png")} alt="" className="w-75 zoom-in-out-box" />
            </div> */}
            <div className="col-md-5 banner-right"></div>
          </div>
        </div>
      </section>
      <section className="cp-logo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <img src="assets/images/cplogo.png" alt="" /> */}
            </div>
          </div>
        </div>
      </section>


      {/* <TestimonialSlider /> */}
      <section className="testimonials-sec"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <ul>
              {
                allTestimonial?.map((v, i) => {
                  const loopCount = parseInt(v?.rating);
                  var image = v.image;
                  return image == '' ? (
                    <li className="google_rev" key={i}>
                      <div className="test_item">

                        <div className="initial">
                          <h3 className="animated fadeIn">{v?.name}</h3>
                        </div>
                        <div className="testicontent">
                          <p>{v?.message}</p>
                          <h4>{v?.name}</h4>
                          <div style={{ position: "absolute", bottom: "40px" }}><p>{v?.email}</p></div>
                          <span className="stars">
                            {
                              loopCount == 1 ? (
                                <>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </>
                              ) : loopCount == 2 ? (
                                <>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </>
                              ) : loopCount == 5 ? (
                                <>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                </>
                              ) : loopCount == 3 ? (
                                <>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </>
                              ) : loopCount == 4 ? (
                                <>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star classActiveColor" aria-hidden="true"></i>
                                  <i className="fa fa-star" aria-hidden="true"></i>
                                </>
                              ) : null
                            }

                          </span>
                        </div>
                      </div>
                    </li>

                  ) : <li className="google_rev" key={i}>
                    <div className="test_item"><img src={`https://madalphadesigners.com/admin/public/uploads/${image}`} />
                    </div>
                  </li>;
                })
              }
            </ul>

            <div className="col-12" style={{ marginTop: 130 }}>
              {/* <div className='row'>
                <div className="col-md-6 col-12">
                  <ReviewForm />
                </div>
                <div className="col-md-6 col-12">
                  <img style={{ borderRadius: 10 }} src="https://media.istockphoto.com/id/1447969085/photo/business-people-using-smartphone-and-pressing-review-popup-on-visual-screen-customer-review.jpg?s=612x612&w=0&k=20&c=LEAGseFr_Pvg0l1EWeynEuGoRspCBhTJ2Q567n6P4Uk=" alt="review" />
                </div>
              </div> */}
              <ReviewForm />
            </div>
          </div>
        </div>
      </section >

      <HomeForm />
    </>

  )
}

export default Testimonial