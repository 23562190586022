import React, { useEffect, useState } from "react";
import "./FAB.css"; // Import the CSS file for styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"; // Import WhatsApp icon
import { useNavigate } from "react-router-dom";

const FAB = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true); // State to track FAB visibility
    const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position
    const navigati = useNavigate()
    const toggleFAB = () => {
        setIsOpen(!isOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // User scrolled down
            setIsVisible(false);
        } else {
            // User scrolled up
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY); // Update last scroll position
    };

    // useEffect(() => {
    //     // Add event listener for scroll
    //     window.addEventListener("scroll", handleScroll);

    //     // Cleanup event listener on component unmount
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, [lastScrollY]);
    return (
        <div className={`fab-container ${!isVisible ? "hide" : ""}`}>
            <div className={`fab-main ${isOpen ? "open" : ""}`} onClick={toggleFAB}>
                <i className="fab-icon" style={{ fontSize: 20, fontWeight: 700, color: "#111" }}>{isOpen ? "X" : "?"}</i>
            </div>
            <div className={`fab-button phone ${isOpen ? "show" : ""}`}>
                {/* <i className="fab-icon">
                   <FontAwesomeIcon />
                </i> */}
                <FontAwesomeIcon
                    onClick={() => {
                        window.location.href="https://api.whatsapp.com/send?phone=+14094193427"
                    }}
                    icon={faWhatsapp} className="fab-icon" color="green" style={{ color: "green", fontSize: 25 }} />

            </div>
            <div className={`fab-button edit ${isOpen ? "show" : ""}`}>
                {/* <i className="fab-icon">✏️</i> */}
            </div>
            <div className={`fab-button camera ${isOpen ? "show" : ""}`}>
                {/* <i className="fab-icon">📷</i> */}
                <FontAwesomeIcon onClick={() => {
                   window.location.href="https://x.com/MADAlphaDesigns"
                }} icon={faTwitter} className="fab-icon" style={{ color: "#1DA1F2", fontSize: 25 }} />
            </div>
            <div className={`fab-button search ${isOpen ? "show" : ""}`}>
                {/* <i className="fab-icon">🔍</i> */}
                <FontAwesomeIcon
                    onClick={() => {
                        window.location.href="https://www.facebook.com/profile.php?id=100083086881212&mibextid=LQQJ4d"
                    }}
                    icon={faFacebook} className="fab-icon" style={{ color: "#1877F2", fontSize: 25 }} />
            </div>
        </div>
    );
};

export default FAB;
