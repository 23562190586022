import React from 'react'
import { useNavigate } from 'react-router-dom'

const ThankYou = () => {
    const navigate = useNavigate();
    return (
        <div className='mt-5 mb-5' style={{ marginTop: '6rem' }}>
            <div className='container mt-5'>
                <div className='row mt-5'>
                    <div className='col-12 mt-5 mb-5'>
                        <h4 className='text-center font-bold ' style={{ fontWeight: "bold" }}>Thank You!</h4>
                        <h2 className='text-center ' style={{ fontSize: 30, fontWeight: 'bold' }}>We've Noted That Down</h2>
                        <p className='text-center' style={{ fontSize: 18 }}>Thankyou for the information that you just submitted to us! We've noted it down and will get back to you soon</p>
                       
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}> <button style={{
                        padding: '5px 18px',
                        borderRadius: '4px',
                        fontWeight: 700,
                        background: 'transparent',
                        border: '1px solid #ffff',
                        color: '#fff',
                        cursor: 'pointer',
                    }}
                    onClick={()=>{
                       navigate("/",{replace:true});
                    }}
                    className='text-center'>Go to Home</button></div>
                </div>
            </div>
        </div>
    )
}

export default ThankYou