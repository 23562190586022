import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const HomeForm = ({ background }) => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        image: null,
        message: ''
    });
    const [loader, setLoader] = useState(false);
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('number', formData.number);
        formDataToSend.append('message', formData.message);
        if (formData.file) {
            formDataToSend.append('image', formData.file); // Append the file
        }
        try {
            setLoader(true);
            const response = await axios.post('https://madalphadesigners.com/admin/public/api/sendMail', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setLoader(false);
            if (response.status == 200) {
                navigate("/thankyou")
            }  // Success message from the server
        } catch (error) {
            console.error('Error sending email:', error);
            setLoader(false);
            alert('Failed to send email.');
        }

    };




    return (
        <section className="footer-form"
            style={{
                background: background ?? 'linear-gradient(45deg, #25328ef2, transparent)'
            }}
        >

            <div className="container">
                <div className="row">
                    <div className="col-md-5 contact-head">
                        <h2>
                            Ready to Take Your Streams in the Right Direction?
                            {/* <br />
                            Get Your Business <br /> the <u style={{ color: "#87a2ebb5" }} className='perfectText'>Perfect Direction </u> */}
                        </h2>
                        <h4>
                            Our design consultants will provide a comprehensive approach to help drive your business success.
                        </h4>
                    </div>
                    <div className="col-md-7 contact-form">
                        <form

                            onSubmit={handleSubmit}
                            // action="webpages/bannerFormController.php"
                            // method="POST"
                            className="banner-form-val"
                        >
                            <div className="cf-fieds">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="required placeholder2 form-control"
                                                required=""
                                                placeholder="Full Name"
                                                name="name"
                                                value={formData.name} onChange={handleChange}
                                                aria-required="true"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="required email placeholder2 form-control"
                                                required=""
                                                value={formData.email} onChange={handleChange}

                                                placeholder="Email Address"
                                                name="email"
                                                aria-required="true"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="required number placeholder2 form-control"
                                                minLength={10}
                                                maxLength={15}
                                                required=""
                                                value={formData.number} onChange={handleChange}

                                                placeholder="Phone Number"
                                                name="number"
                                                aria-required="true"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="file"
                                                className="required  imageUploader1  form-control"
                                                required=""
                                                name="image"
                                                onChange={handleChange}

                                                aria-required="true"
                                                style={{ height: "40px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <textarea
                                                name="message"
                                                className="required placeholder2 form-control"
                                                required=""
                                                placeholder="To help us understand better, enter a brief description about your project."
                                                aria-required="true"
                                                defaultValue={""}
                                                value={formData.message} onChange={handleChange}

                                            />
                                        </div>
                                        <div className="sbmit-btn">
                                            <input
                                                style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
                                                type="submit" disabled={loader} value={loader ? "Please Wait..." : "Submit "} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeForm